import { Store } from 'redux';
import { Easeljs } from '../../easeljs';
import { Groovy, AppStateAll } from '../../types';
import getStore from '../../redux/store';
import { addPartToSong } from '../../redux/actions/song_actions';
import { partMenuButtonDown, partMenuButtonUp } from '../../redux/actions/menu_actions';

class ButtonController {
  private thumb: Groovy.Point | null = null
  private origPos = {
    x: 0,
    y: 0,
  };

  private _parent: Easeljs.Container | null = null
  get parent(): Easeljs.Container | null {
    return this._parent;
  }
  set parent(parent: Easeljs.Container | null) {
    this._parent = parent;
  }

  private _container: Easeljs.Container
  get container(): Easeljs.Container {
    return this._container;
  }
  set container(container: Easeljs.Container) {
    this._container = container;
  }

  private _threshold: { x: number, y: number } = { x: 0, y: 0 }
  set threshold(threshold: { x: number, y: number }) {
    this._threshold = threshold;
  }

  private _animController: Groovy.SpriteAnimationController;
  get animController(): Groovy.SpriteAnimationController {
    return this._animController;
  }

  private store: Store<AppStateAll>
  private stage: Easeljs.Stage
  private canvas: HTMLCanvasElement
  private touchEventIdentifier: number
  private boundTouchMove: (e: TouchEvent) => void
  private boundMouseMove: (e: MouseEvent) => void
  private boundDown: (o: Object) => void
  private boundUp: (o: Object) => void

  constructor(animController: Groovy.SpriteAnimationController) {
    this.store = getStore();
    ({ app: { stage: this.stage } } = this.store.getState());
    this._animController = animController
    this.canvas = this.stage.canvas as HTMLCanvasElement;
    this._container = animController.container;
    this.touchEventIdentifier = -1;

    this.boundUp = this.up.bind(this);
    this.boundDown = this.down.bind(this);
    this.boundMouseMove = this.moveMouse.bind(this);
    this.boundTouchMove = this.moveTouch.bind(this);
    this.container.on('mousedown', this.boundDown);
    this.container.on('click', this.boundUp);
  }

  private move(x: number, y: number) {
    if (this.thumb !== null) {
      const diffX = x - this.thumb.x;
      const diffY = y - this.thumb.y;
      this.container.x += diffX;
      this.container.y += diffY;
      this.thumb.x = x;
      this.thumb.y = y;

      if (this.container.y - this.origPos.y > this._threshold.y && this.container.x > this._threshold.x) {
        if (this.parent !== null) {
          const p = this.parent.localToGlobal(this.container.x, this.container.y)
          const thumb = {
            x: x - p.x,
            y: y - p.y,
          }
          this.store.dispatch(addPartToSong(
            this.animController.trackName,
            this.animController.numNotes,
            this.animController.scale,
            { x, y }, thumb, this.touchEventIdentifier))
        }
        this.up();
      }
    }
  }

  private moveMouse(e: MouseEvent) {
    const x = e.clientX;
    const y = e.clientY;
    this.move(x, y);
  }

  private moveTouch(e: TouchEvent) {
    let current: Touch | null = null;
    for (let i = 0; i < e.changedTouches.length; i++) {
      const tmp = e.changedTouches[0];
      if (tmp.identifier === this.touchEventIdentifier) {
        current = tmp;
        break;
      }
    }
    if (current !== null) {
      const x = current.clientX;
      const y = current.clientY;
      this.move(x, y);
    }
  }

  private down(o: Object) {
    const e = o as Easeljs.MouseEvent
    const ne = e.nativeEvent;
    const te = ne as TouchEvent;
    const me = ne as MouseEvent;
    let x = 0;
    let y = 0;
    if (te.changedTouches) {
      const e = te.changedTouches[0];
      this.touchEventIdentifier = e.identifier;
      x = e.clientX;
      y = e.clientY;
      // this.thumb = this.container.globalToLocal(x, y);
      this.thumb = { x, y };
      this.canvas.addEventListener('touchmove', this.boundTouchMove);
    } else {
      x = me.clientX;
      y = me.clientY;
      // this.thumb = this.container.globalToLocal(x, y);
      this.thumb = { x, y };
      this.canvas.addEventListener('mousemove', this.boundMouseMove);
    }
    this.store.dispatch(partMenuButtonDown(this.animController.trackName));
  }

  private up() {
    this.canvas.removeEventListener('mousemove', this.boundMouseMove);
    this.canvas.removeEventListener('touchmove', this.boundTouchMove);
    this.store.dispatch(partMenuButtonUp());
    this.container.x = this.origPos.x// * this.animController.scale;
    this.container.y = this.origPos.y// * this.animController.scale;
  }

  setOrigPosition(x: number, y: number) {
    this.container.x = x;
    this.container.y = y;
    this.origPos.x = x;
    this.origPos.y = y;
  }
}

export {
  ButtonController,
};