import { Easeljs } from '../../easeljs';
import { createjs } from '../app';
import { loadSpriteSheet } from '../../util/load_spritesheet';
import { Groovy } from '../../types';

const createSprite = async (url: string): Promise<Groovy.SpriteAnimation> => {
  const container: Easeljs.Container = new createjs.Container();
  const [spriteSheet, data] = await loadSpriteSheet(url);
  const numFrames = spriteSheet.getNumFrames() / 2;
  for (let i = (numFrames * 2) - 1; i >= 0; i--) {
    const b: Easeljs.Sprite = new createjs.Sprite(spriteSheet);
    b.gotoAndStop(i);
    container.addChildAt(b);
  }

  return {
    instrument: data.instrument,
    container,
    numFrames,
    spriteSheet,
  }
}

const createSpriteArpeggios = async (url: string): Promise<Groovy.SpriteAnimation> => {
  return {
    instrument: '',
    container: new createjs.Container(),
    numFrames: 0,
    spriteSheet: new createjs.SpriteSheet(),
  }
}

const createSpriteRhythms = async (url: string): Promise<Groovy.SpriteAnimation> => {
  return {
    instrument: '',
    container: new createjs.Container(),
    numFrames: 0,
    spriteSheet: new createjs.SpriteSheet(),
  }
}

export {
  createSprite,
  createSpriteArpeggios,
  createSpriteRhythms,
}