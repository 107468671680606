/**
 *  - loads spritesheet JSON data 
 *  - loads all necessary images
 *  - converts images to HTML image elements
 *  - colorises images
 */

import { fetchYAML, loadImages } from './load_helpers';
import { Easeljs } from '../easeljs';
import { createjs } from '../easeljs/app';
import { colorizeCanvas } from './colorise_canvas';
import { Groovy } from '../types';


export const loadSpriteSheet = async (arg: string | Groovy.SpriteSheetData): Promise<[Easeljs.SpriteSheet, Groovy.SpriteSheetData]> => {
  let data;
  if (typeof arg === 'string') {
    const url = arg;
    data = await fetchYAML(url) as Groovy.SpriteSheetData;
  } else {
    data = arg;
  }
  data.images = await loadSpriteSheetImages(data);
  // we only need to colorise the passive image
  const colorised = await colorizeCanvas(data.images[0] as HTMLImageElement, 'passive', data.color);
  data.images[0] = colorised[1];
  const spriteSheet: Easeljs.SpriteSheet = new createjs.SpriteSheet(data);
  return [spriteSheet, data];
};

export const loadSpriteSheetImages = async (data: Groovy.SpriteSheetData): Promise<Array<HTMLImageElement>> => {
  const tmp: Array<string> = data.images.filter(i => typeof i === 'string') as Array<string>
  return await loadImages(tmp) as Array<HTMLImageElement>;
}
