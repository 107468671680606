import { Store } from 'redux';
import { combineLatest, Observable, GroupedObservable } from 'rxjs';
import { distinctUntilKeyChanged, map, pluck } from 'rxjs/operators';
import { AppStateAll, Groovy } from '../../types';
import { AppState } from 'src/redux/reducers/app_reducer';

let height$: Observable<number> | null = null;
let width$: Observable<number> | null = null;
let partIconScale$: Observable<number>;
let keyeditorMarginLeft$: Observable<number>;
let store: Store<AppStateAll>;

const createResizeObservables = (state$: Observable<AppStateAll>, s: Store<AppStateAll>) => {
  store = s;
  width$ = state$.pipe(
    pluck('app'),
    distinctUntilKeyChanged('canvasWidth'),
    map(app => app.canvasWidth),
    // map(app => [app.canvasWidth, app.partIconScale]),
  );

  height$ = state$.pipe(
    pluck('app'),
    distinctUntilKeyChanged('canvasHeight'),
    map(app => app.canvasHeight),
  );

  partIconScale$ = state$.pipe(
    pluck('app'),
    distinctUntilKeyChanged('partIconScale'),
    map(app => app.partIconScale),
  );

  keyeditorMarginLeft$ = state$.pipe(
    pluck('app'),
    distinctUntilKeyChanged('keyeditorMarginLeft'),
    map(app => app.keyeditorMarginLeft),
  );
  /*
    combineLatest(width$, height$)
      .subscribe(dimensions => {
        if (store !== null) {
          const [width, height] = dimensions;
          const {
            app: {
              scale,
              keyeditorMarginLeft,
              keyeditorMarginRight
            },
            song: {
              startBar,
              totalBars,
              barsPerPage,
              minBarWidth,
              pageOffsetX,
              barWidth,
            }
          } = store.getState();
          const data = {
            width,
            height,
            scale,
            barsPerPage,
            barWidth,
            keyeditorMarginLeft,
            keyeditorMarginRight,
            startBar,
            pageOffsetX,
          }
          // console.log(width);
          resizeCallbacks.forEach(cb => {
            cb(data)
          });
        }
      })
  */
}

const resizeCallbacks: Array<(d: Groovy.ResizeData) => void> = [];
const addResizeCallback = (cb: (d: Groovy.ResizeData) => void) => {
  resizeCallbacks.push(cb)
}

const handleResizeSubscription = (callback: (d: Groovy.ResizeData) => void) => {
  if (width$ !== null && height$ !== null) {
    combineLatest(width$, height$)
      .subscribe(dimensions => {
        if (store !== null) {
          const [width, height] = dimensions;
          const {
            app: {
              scale,
              keyeditorMarginLeft,
              keyeditorMarginRight,
              partIconScale,
            },
            song: {
              startBar,
              totalBars,
              barsPerPage,
              minBarWidth,
              pageOffsetX,
              barWidth,
              partsOnStage,
            }
          } = store.getState();
          const data = {
            width,
            height,
            scale,
            barsPerPage,
            barWidth,
            keyeditorMarginLeft,
            keyeditorMarginRight,
            startBar,
            pageOffsetX,
            partsOnStage,
            partIconScale,
          }
          // console.log(width);
          callback(data)
        }
      })
  }
}

const handleHeightSubscription = (callback: (h: number) => void) => {
  if (height$ !== null) {
    height$.subscribe(height => {
      callback(height);
    })
  }
}

const partMenuScaleData = (callback: (s: number, o: number, w: number) => void) => {
  combineLatest(partIconScale$, keyeditorMarginLeft$)
    .subscribe(data => {
      const canvasWidth = store.getState().app.canvasWidth
      callback(data[0], data[1], canvasWidth);
    })
}

const partMenuResizeData = (callback: (w: number, h: number, s: number) => void) => {
  if (width$ !== null && height$ !== null) {
    combineLatest(width$, height$)
      .subscribe((data: [number, number]) => {
        const partIconScale = store.getState().app.partIconScale
        callback(data[0], data[1], partIconScale);
      })
  }
}

export {
  createResizeObservables,
  handleResizeSubscription,
  handleHeightSubscription,
  partMenuScaleData,
  partMenuResizeData,
};

