export const SEQUENCER_READY = 'SEQUENCER_READY';
export const LOAD_MIDIFILE = 'LOAD_MIDIFILE';
export const LOAD_INSTRUMENT = 'LOAD_INSTRUMENT';
export const PARSE_INSTRUMENT = 'PARSE_INSTRUMENT';
export const INSTRUMENT_LOADED = 'INSTRUMENT_LOADED';
export const AVATAR_LOADED = 'AVATAR_LOADED';


import {
  initSequencer,
  loadJSON,
  parseConfig,
  createMIDIFileList,
  getLoadedInstruments,
  addAssetPack,
  addMIDIFile,
  createSongFromMIDIFile,
  loadArrayBuffer,
} from '../../util/action_utils';
import { createSprite } from '../../easeljs/animations/sprite_animation';
import { fetchYAML } from '../../util/load_helpers';
import { Dispatch, AnyAction, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppStateAll } from 'src/types';

export const init = async () => {
  await initSequencer();
  const song = await createSongFromMIDIFile('/assets/midi/SH-3-44.mid');
  const bass = await createSprite('/assets/bass.yaml');
  const melody = await createSprite('/assets/melody.yaml');
  const chords = await createSprite('/assets/chords.yaml');
  const arpeggios = await createSprite('/assets/arpeggios.yaml');
  const rhythms = await createSprite('/assets/rhythm.yaml');
  const instruments = await fetchYAML('/assets/instruments.yaml')
  return {
    type: SEQUENCER_READY,
    payload: {
      song,
      bass,
      melody,
      chords,
      arpeggios,
      rhythms,
      instruments,
    }
  }
}

export const loadMIDIFile = (url: string) => ({
  type: LOAD_MIDIFILE,
  payload: {
    url,
  }
});

export const loadInstrument = (url: string): ThunkAction<Promise<void>, {}, AppStateAll, AnyAction> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOAD_INSTRUMENT,
    })
    const json = await loadJSON(url);
    dispatch({
      type: PARSE_INSTRUMENT,
    })
    await addAssetPack(json);
    dispatch({
      type: INSTRUMENT_LOADED,
    })
  }
};

export const avatarLoaded = () => ({
  type: AVATAR_LOADED
})

