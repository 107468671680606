import { AppStateAll } from '../../types';
import { AnyAction, Reducer } from 'redux';
import { RESIZED } from '../actions/app_actions';
import { appReducer } from './app_reducer';
import { songReducer } from './song_reducer';
import { avatarReducer } from './avatar_reducer';
import { initialState } from '../store';
import sequencer from 'heartbeat-sequencer';
import { menuReducer } from './menu_reducer';

const getScale = (width: number): number => {
  if (width < 360) {
    return 0.4;
  } else if (width < 600) {
    return 0.5;
  } else if (width < 940) {
    return 0.7;
  } else if (width < 1280) {
    return 1;
  }
  return 1.2;
}

export const rootReducer: Reducer<AppStateAll, AnyAction> = (state: AppStateAll | undefined = initialState, action: AnyAction): AppStateAll => {
  const newState = {
    app: appReducer(state.app, action),
    menu: menuReducer(state.menu, action),
    song: songReducer(state.song, action),
    avatar: avatarReducer(state.avatar, action),
  }
  // console.log(newState.app.thumbs);

  if (action.type === RESIZED) {
    const {
      width,
      height,
      topMenuHeight,
      bottomMenuHeight,
    } = action.payload;
    const scale = width / state.app.origWidth;
    const canvasWidth = width - (state.app.canvasMargin * 2);
    let {
      keyeditorMargins,
    } = state.app;
    let {
      pageOffsetX,
      minBarWidth,
      totalBars,
      song,
      startBar,
    } = state.song
    let marginIndex = 1;
    if (width < 600) {
      marginIndex = 0;
    }
    const keyeditorMarginLeft = keyeditorMargins.left[marginIndex];
    const keyeditorMarginRight = keyeditorMargins.right[marginIndex];
    const scoreWidth = canvasWidth - keyeditorMarginLeft - keyeditorMarginRight;
    let barsPerPage = 0;
    let barWidth = Number.MAX_VALUE;
    while (barWidth > minBarWidth) {
      barWidth = scoreWidth / ++barsPerPage
    }
    barsPerPage = Math.min(totalBars, --barsPerPage);
    barWidth = scoreWidth / barsPerPage;
    if (song !== null) {
      const bar = song.bar - 1;
      startBar = Math.min(bar, totalBars - barsPerPage);
      pageOffsetX = startBar * barWidth;
    }

    const partIconScale = getScale(width)
    return {
      ...newState,
      app: {
        ...newState.app,
        partIconScale,
        width,
        height,
        scale,
        topMenuHeight,
        bottomMenuHeight,
        keyeditorMarginLeft,
        keyeditorMarginRight,
        canvasWidth,
        canvasHeight: height - topMenuHeight - bottomMenuHeight,// - (state.app.canvasMargin * 2),
      },
      song: {
        ...newState.song,
        barsPerPage,
        barWidth,
        scoreWidth,
        startBar,
        pageOffsetX,
      }
    }
  }
  return newState;
}
