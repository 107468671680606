import getStore from '../../redux/store'
import { updateSongPositionTicks, songTransport, updateSong, updateKeyEditor, partRemovedFromSong } from '../../redux/actions/song_actions'
import { Groovy } from '../../types';
import sequencer from 'heartbeat-sequencer';
import createGrid from './grid';
import { getLowestNote, getHighestNote, getAverageNote } from '../../util/action_utils';

const createSongWrapper = () => {
  const store = getStore();
  const {
    totalBars,
    barsPerPage,
  } = store.getState().song;
  const {
    songPartsMenu
  } = store.getState().app
  const song: Heartbeat.Song = sequencer.createSong({
    bars: totalBars,
    useMetronome: true,
    autoSize: false,
  })
  store.dispatch(updateSong(song));

  const keyEditor: Heartbeat.KeyEditor = sequencer.createKeyEditor(song, {
    keyListener: true,
    viewportHeight: 100,
    viewportWidth: 100,
    lowestNote: 21,
    highestNote: 108,
    barsPerPage,
  });
  store.dispatch(updateKeyEditor(keyEditor));
  const grid = createGrid(keyEditor);

  song.addEventListener('end', () => {
    store.dispatch(songTransport('stop'));
    cancelAnimationFrame(updater);
  })

  let updater = 0;
  let delay = 0;
  const update = () => {
    delay++;
    if (delay % 2 === 0) {
      if (song !== null) {
        store.dispatch(updateSongPositionTicks(song.ticks));
      }
    }
    updater = requestAnimationFrame(update);
  }

  const controlSong = (control: string) => {
    if (song !== null) {
      if (control === 'play') {
        song.play();
        update();
      } else if (control === 'stop') {
        cancelAnimationFrame(updater);
        if (song.paused === false && song.playing === true) {
          song.pause();
        }
      } else if (control === 'rewind') {
        cancelAnimationFrame(updater);
        song.stop();
      }
    }
  }

  const setPlayheadFromX = (x: number) => {
    song.setPlayhead('ticks', keyEditor.xToTicks(x));
  }

  const addPart = (animController: Groovy.SpriteAnimationController, data: Groovy.NewPart) => {
    if (song === null || songPartsMenu === null) {
      return
    }
    const { trackName } = animController;
    const { thumb, pointer } = data;
    const p = grid.getContainer().globalToLocal(pointer.x - thumb.x, pointer.y - thumb.y)
    const hasTrack = song.tracks.findIndex(t => t.name === trackName);
    let track;
    if (hasTrack === -1) {
      const {
        app: { instruments },
        menu: { instrument },
      } = store.getState();
      track = sequencer.createTrack(trackName);
      track.setInstrument(instruments[instrument].srcname)
    } else {
      track = song.tracks[hasTrack];
    }
    const part = sequencer.createPart();
    part.id = part.name = animController.id;
    const events: Heartbeat.MIDIEvent[] = songPartsMenu.tracks.filter(t => t.name === trackName)[0].events.map(e => e.clone());
    // console.log(trackName);
    // events.forEach(e => { console.log(e.command, e.data1, e.data2) });
    let ticks = keyEditor.xToTicks(p.x);
    ticks = Math.max(ticks, 0);
    part.addEvents(events);
    // const lowestNote = getLowestNote(events).noteNumber;
    const highestNote = getHighestNote(events).noteNumber;
    // const averageNote = getAverageNote(events);
    const targetPitch = keyEditor.yToPitch(p.y).number
    // console.log(events.filter(e => e.command === 144));
    // console.log(highestNote);
    // const transpose = targetPitch - lowestNote;
    // const transpose = targetPitch - averageNote;
    const transpose = targetPitch - highestNote;
    part.transposeAllEvents(transpose);
    events.forEach(e => {
      e.transpose(transpose);
    })
    track.addPartAt(part, ['ticks', ticks]);
    song.addTrack(track);
    song.update();
  }

  const movePart = (data: Groovy.SelectedPart) => {
    const { action, id, x, y } = data
    if (action === 'start') {
      const part = song.parts.filter(p => p.id === id)[0]
      if (part) {
        keyEditor.startMovePart(part, x, y);
      }
    } else if (action === 'stop') {
      keyEditor.stopMovePart()
      grid.stopMovePart(id);
    } else if (action === 'move') {
      keyEditor.movePart(x, y);
    }
    const snapshot = keyEditor.getSnapshot();
    if (snapshot.parts.changed.length > 0) {
      const p = snapshot.parts.changed[0];
      grid.movePart(id, p.bbox.x, p.bbox.y);
      // console.log(y, keyEditor.yToPitch(y));
    }
  }

  const removePart = (partId: string) => {
    // TODO: improve this!
    let p: Heartbeat.Part[] = [];
    const t = song.tracks.filter(t => {
      p = t.parts.filter(p => p.id === partId);
      return p.length > 0;
    })[0];
    if (t && p[0]) {
      // console.log(t, p[0]);
      t.removePart(p[0]);
      // t.update();
      song.update();
      store.dispatch(partRemovedFromSong());
    }
    // console.log(partId, t[0]);
  }

  return {
    controlSong,
    addPart,
    setPlayheadFromX,
    getGrid: () => grid,
    movePart,
    removePart,
  }
}
export default createSongWrapper;