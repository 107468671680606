import React from 'react';

export const Loader = (props: { message: string, percentage: number }) => {
  return (
    <div id="loader">
      <span>{props.message}</span>
    </div>
  );
};

export default Loader

