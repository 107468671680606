import { Observable, Subscriber } from 'rxjs';
import { compose, applyMiddleware, createStore, Store, combineReducers, DeepPartial, Reducer, AnyAction, Action } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import { AppStateAll } from '../types';
import { appReducer, AppState, appInitialState } from './reducers/app_reducer';
import { avatarReducer, AvatarState, avatarInitialState } from './reducers/avatar_reducer';
import { songReducer, SongState, songInitialState } from './reducers/song_reducer';
import { menuInitialState } from './reducers/menu_reducer';
import { rootReducer } from './reducers/root_reducer';


// const initialState: DeepPartial<{ app: AppState, avatar: AvatarState, song: SongState }> = {
//   app: appInitialState,
//   avatar: avatarInitialState,
//   song: songInitialState,
// };
// const combinedReducers = combineReducers({ app, avatar, song, resize });
// const combinedReducers: Reducer<AppStateAll, AnyAction> = combineReducers({ app, avatar });


const initialState: AppStateAll = {
  app: appInitialState,
  menu: menuInitialState,
  avatar: avatarInitialState,
  song: songInitialState,
};

// const combinedReducers = (state = initialState, action: AnyAction) => {
//   return {
//     app: appReducer(state.app, action),
//     avatar: avatarReducer(state.avatar, action),
//     song: songReducer(state.song, action),
//   }
// }

const store: Store<AppStateAll, AnyAction> = createStore(
  // combinedReducers,
  rootReducer,
  initialState,
  composeWithDevTools(
    // compose(
    applyMiddleware(
      thunkMiddleware,
      // createLogger({ collapsed: true }),
    ),
  ),
);

const getStore = (): Store<AppStateAll, AnyAction> => {
  return store;
};

const state$ = new Observable((observer: Subscriber<AppStateAll>) => {
  observer.next(store.getState());
  const unsubscribe = store.subscribe(() => {
    observer.next(store.getState());
  });
  return unsubscribe;
});

const getState$ = (): Observable<AppStateAll> => {
  return state$;
}

export { getState$, initialState };
export default getStore;