import {
  SELECT_TOPMENU_ITEM,
  PART_MENU_HIDDEN,
  PART_MENU_BUTTON_DOWN,
  PART_MENU_BUTTON_UP,
  PART_MENU_SCROLL,
  PART_MENU_MAX_SCROLL,
} from '../actions/menu_actions';
import { AnyAction } from 'redux';

export interface MenuState {
  selectedTopMenuItem: string
  showPartMenu: boolean
  selectedTrackName: string,
  instrumentMapping: { [id: string]: string }
  instrument: string
  selectedPart: {
    partId: string
    x: number
    y: number
  } | null
  partMenuScroll: number
  partMenuMaxScroll: boolean
}

export const menuInitialState: MenuState = {
  selectedTopMenuItem: '',
  showPartMenu: false,
  selectedTrackName: '',
  instrumentMapping: {
    bass: 'S03',
    chord: 'TP03',
    arpeggio: 'S05',
    melody: 'TP00',
    rhythm: 'SHP1',
  },
  instrument: '',
  selectedPart: null,
  partMenuScroll: 0,
  partMenuMaxScroll: false,
};

export const menuReducer = (state: MenuState = menuInitialState, action: AnyAction): MenuState => {
  if (action.type === SELECT_TOPMENU_ITEM) {
    let s = action.payload.selected;
    let instrument = state.instrumentMapping[s]
    const showPartMenu = state.selectedTopMenuItem === '';
    if (s === state.selectedTopMenuItem) {
      s = '';
      instrument = '';
    }
    return {
      ...state,
      showPartMenu,
      instrument,
      partMenuScroll: 0,
      partMenuMaxScroll: false,
      selectedTopMenuItem: s,
    }
  } else if (action.type === PART_MENU_HIDDEN) {
    const showPartMenu = state.selectedTopMenuItem !== '';
    return {
      ...state,
      showPartMenu,
    }
  } else if (action.type === PART_MENU_BUTTON_DOWN) {
    return {
      ...state,
      selectedTrackName: action.payload.trackName,
    }
  } else if (action.type === PART_MENU_BUTTON_UP) {
    return {
      ...state,
      selectedTrackName: '',
    }
  } else if (action.type === PART_MENU_SCROLL) {
    let partMenuScroll = state.partMenuScroll;
    const { payload: { direction } } = action
    if (direction === 'left') {
      partMenuScroll = Math.max(0, --partMenuScroll)
    } else if (direction === 'right') {
      partMenuScroll++;
    }
    return {
      ...state,
      partMenuScroll,
      partMenuMaxScroll: false
    }
  } else if (action.type === PART_MENU_MAX_SCROLL) {
    return {
      ...state,
      partMenuMaxScroll: action.payload.flag,
    }
  }
  return state;
}
