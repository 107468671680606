import { Groovy } from '../../types';

export const UPDATE_SONG = 'UPDATE_SONG';
export const SONG_TRANSPORT = 'SONG_TRANSPORT';
export const UPDATE_SONG_POSITION_TICKS = 'UPDATE_SONG_POSITION_TICKS';
export const SET_PAGE_OFFSET_X = 'SET_PAGE_OFFSET_X';
export const SET_SONG_POSITION_FROM_X = 'SET_SONG_POSITION_FROM_X';
export const UPDATE_KEY_EDITOR = 'UPDATE_KEY_EDITOR';
export const SCORE_NAVIGATION = 'SCORE_NAVIGATION';
export const ADD_PART_TO_SONG = 'ADD_PART_TO_SONG';
export const REMOVE_PART_FROM_SONG = 'REMOVE_PART_FROM_SONG';
export const PART_REMOVED_FROM_SONG = 'PART_REMOVED_FROM_SONG';
export const START_DRAG_PART = 'START_DRAG_PART';
export const STOP_DRAG_PART = 'STOP_DRAG_PART';
export const DRAG_PART = 'DRAG_PART';


export const updateSong = (song: Heartbeat.Song) => ({
  type: UPDATE_SONG,
  payload: {
    song,
  }
})

export const songTransport = (transport: string) => ({
  type: SONG_TRANSPORT,
  payload: {
    transport,
  }
})

export const updateSongPositionTicks = (ticks: number) => ({
  type: UPDATE_SONG_POSITION_TICKS,
  payload: {
    ticks,
  }
})

export const setPageOffset = (offset: number) => ({
  type: SET_PAGE_OFFSET_X,
  payload: {
    offset,
  }
})

export const setSongPositionFromX = (x: number) => ({
  type: SET_SONG_POSITION_FROM_X,
  payload: {
    x,
  }
})

export const updateKeyEditor = (k: Heartbeat.KeyEditor) => ({
  type: UPDATE_KEY_EDITOR,
  payload: {
    keyEditor: k,
  }
})

export const scoreNavigation = (command: string) => ({
  type: SCORE_NAVIGATION,
  payload: {
    command,
  }
})

export const addPartToSong = (trackName: string, numNotes: number, scale: number, pointer: Groovy.Point, thumb: Groovy.Point, touchEventIdentifier: number) => ({
  type: ADD_PART_TO_SONG,
  payload: {
    trackName,
    numNotes,
    scale,
    thumb,
    pointer,
    touchEventIdentifier,
  }
})

export const removePartFromSong = (partId: string) => ({
  type: REMOVE_PART_FROM_SONG,
  payload: {
    partId,
  }
})

export const partRemovedFromSong = () => ({
  type: PART_REMOVED_FROM_SONG,
})

export const startDragPart = (partId: string, x: number, y: number) => ({
  type: START_DRAG_PART,
  payload: {
    id: partId,
    x,
    y,
  }
})

export const dragPart = (partId: string, x: number, y: number) => ({
  type: DRAG_PART,
  payload: {
    id: partId,
    x,
    y,
  }
})

// TODO: add multitouch support here!
export const stopDragPart = (partId: string) => ({
  type: STOP_DRAG_PART,
  payload: {
    id: partId,
  }
})
