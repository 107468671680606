import getStore from '../../redux/store';
import {
  handleSelectedTopSubscription,
  handleSelectedTrackNameSubscription,
  handlePartMenuScrollSubscription,
} from '../subscriptions/menu_subscriptions';
import {
  partMenuScaleData,
  partMenuResizeData,
} from '../subscriptions/resize_subscriptions';
import createMenu from './partmenu_menu';
import createSong from './partmenu_song';

const createPartMenu = () => {
  const store = getStore();
  const menu = createMenu(store);
  const song = createSong(store);

  partMenuScaleData(menu.setScale)
  partMenuResizeData(menu.resize)
  handleSelectedTrackNameSubscription(song.playTrack)
  handlePartMenuScrollSubscription(menu.scroll)

  handleSelectedTopSubscription((showPartMenu, selectedTopMenuItem) => {
    if (showPartMenu === true) {
      menu.setMenu(selectedTopMenuItem);
      menu.show();
    } else {
      menu.hide();
    }
    if (selectedTopMenuItem === 'bass' || selectedTopMenuItem === 'chord' || selectedTopMenuItem === 'melody') {
      song.loadInstrument(selectedTopMenuItem);
    }
  });


  return {
  }
}

export default createPartMenu;