import React, { SyntheticEvent } from 'react';

export const TopMenu = (props: { buttons: Array<[string, string]>, onClick: (value: string) => void }) => {
  const buttons = props.buttons.map(data => {
    const [label, className] = data;
    return (<div key={label} onClick={(e) => {
      props.onClick(label);
    }} className={className}></div>)
  })

  return (
    <div id="topmenu">
      {buttons}
    </div>
  );
};

export default TopMenu;

/*
const buttonLabels = [
  'logo',
  'sfx',
  'melody',
  'bass',
  'chord',
  'arpeggio',
  'rhythm',
  'tempo',
  'instruments',
  'dynamics',
  'chromatics',
];

export const TopMenu = (props: { lang: string, theme: string, selected: string, onClick: (value: string) => void }) => {
  const theme = `${props.theme}-${props.lang}`
  const buttons = buttonLabels.map(label => {
    let className = `${label} ${theme}`;
    if (label === props.selected) {
      className = `selected ${label} ${theme}`;
    }
    return (<div key={label} onClick={(e) => {
      props.onClick(label);
    }} className={className}></div>)
  })

  return (
    <div id="topmenu">
      {buttons}
    </div>
  );
};

export default TopMenu;
*/

