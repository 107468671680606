import { Easeljs } from '../../easeljs';
import { addDebug } from '../../util/sprite_utils';
import { Groovy } from '../../types';
import { generateUUID } from '../../util/general';

/*
  controls the following sprite animations:
  - shapes/bass
  - shapes/chords
  - shapes/melodies
*/

class SpriteAnimationController {
  private _id: string
  get id(): string {
    return this._id;
  }
  private spriteAnimation: Groovy.SpriteAnimation
  private numFrames: number

  private _container: Easeljs.Container
  get container(): Easeljs.Container {
    return this._container;
  }

  private _trackName: string
  get trackName(): string {
    return this._trackName;
  }
  set trackName(name: string) {
    this._trackName = name;
  }

  private _numNotes: number
  get numNotes() {
    return this._numNotes;
  }

  get scale(): number {
    return this.container.scaleX;
  }
  set scale(scale: number) {
    this.container.scaleX = scale
    this.container.scaleY = scale
  }

  constructor(s: Groovy.SpriteAnimation) {
    this._id = generateUUID();
    this.spriteAnimation = s;
    this.numFrames = s.numFrames;
    this._container = s.container.clone(true);
    this._trackName = '';
    this._numNotes = 0;
    // addDebug(this.container);
    this.inactivateAll();
  }

  configure(num: number) {
    this._numNotes = num;
    let i = num;
    while (i < this.numFrames) {
      const child = this.container.getChildAt(i) as Easeljs.Sprite;
      child.visible = false;
      i++;
    }
  }

  inactivateAll() {
    let i = 0;
    while (i < this.numFrames) {
      const child = this.container.getChildAt(i + this.numFrames) as Easeljs.Sprite;
      child.visible = false;
      i++;
    }
  }

  inactivate(num: number) {
    const child = this.container.getChildAt(num + this.numFrames) as Easeljs.Sprite;
    child.visible = false;
  }

  activate(num: number) {
    const child = this.container.getChildAt(num + this.numFrames) as Easeljs.Sprite;
    child.visible = true;
  }

  getSpriteAnimation() {
    return this.spriteAnimation;
  }

  test() {
    // let i = 0;
    // setInterval(() => {
    //   inactivateAll();
    //   if (i === numFrames) {
    //     i = 0;
    //   }
    //   activate(i++);
    //   getStore().getState().app.stage.update();
    // }, 500);
    // const bbox = container.getBounds()
    // container.cache(bbox.x, bbox.y, bbox.width, bbox.height);
  }
}

class SpriteAnimationControllerArpeggios extends SpriteAnimationController {
};

class SpriteAnimationControllerRhythms extends SpriteAnimationController {
};


export {
  SpriteAnimationController,
  SpriteAnimationControllerArpeggios,
  SpriteAnimationControllerRhythms,
};