import { Easeljs } from '../../easeljs';
import { Groovy } from '../../types';
import { createjs } from '../app';
import { getNumUniqueNotes } from '../../util/action_utils';
import { ButtonController } from './button_controller';

export type Slider = {
  container: Easeljs.Container
  setScale: (iconScale: number, keyeditorMarginLeft: number) => void
  setPositionX: (x: number) => void
  getWidth: () => number
}

const createSlider = (
  tracks: Array<Heartbeat.Track>,
  controllerClass: new (s: Groovy.SpriteAnimation) => Groovy.SpriteAnimationController,
  sprite: Groovy.SpriteAnimation,
  yPos: number,
  marginX: number,
  keyeditorMarginLeft: number,
): Slider => {
  const container: Easeljs.Container = new createjs.Container();
  let width: number;
  let buttonWidth = 0;
  const buttons: Array<Groovy.ButtonController> = tracks.reverse().map((t, i) => {
    const spriteController = new controllerClass(sprite);
    const n = getNumUniqueNotes(t.parts[0]);
    spriteController.configure(n);
    spriteController.trackName = t.name;

    const buttonController = new ButtonController(spriteController);
    buttonController.parent = container;
    buttonController.threshold = {
      x: keyeditorMarginLeft,
      y: 100,
    }

    const spriteContainer = spriteController.container;
    container.addChild(spriteContainer);

    if (buttonWidth === 0) {
      const b = spriteContainer.getBounds();
      if (b !== null) {
        buttonWidth = b.width;
      } else {
        buttonWidth = 100;
      }
    }
    return buttonController;
  })

  const geom: Easeljs.Shape = new createjs.Shape();
  const graphics = geom.graphics;
  container.addChildAt(geom, 0)
  const setScale = (iconScale: number, keyeditorMarginLeft: number) => {
    // console.log('BUTTON', scale);
    width = 50; // margin for the navigate button
    buttons.forEach((b, i) => {
      if (b !== null) {
        b.animController.scale = iconScale;
        b.setOrigPosition(width, yPos);
        width += (iconScale * buttonWidth) + (iconScale * marginX);
        // c.x = i * ((iconScale * buttonWidth) + (iconScale * marginX));
        b.threshold = {
          x: keyeditorMarginLeft,
          y: iconScale * 100,
        }
      }
    })
    width += (iconScale * buttonWidth) + (iconScale * marginX);
    graphics.clear();
    graphics.beginFill('#9cbcc4').drawRect(0, 0, width, iconScale * 90);
    // console.log(width);
  }

  return {
    container,
    setScale,
    setPositionX: (x: number) => { container.x = x },
    getWidth: () => width,
  };
}

export default createSlider

