import { Easeljs } from '../../easeljs';
import { fetchJSON, loadImages } from '../../util/load_helpers';
import { colorizeCanvas } from '../../util/colorise_canvas';
import { createjs } from '../app';
import { Groovy } from '../../types';

const colorMapping: ColorMapping = {
  leftArm: 'defaultUpperBodyColour',
  leftHand: 'defaultSkinColour',
  face: 'defaultSkinColour',
  shoes: 'defaultShoesColour',
  legs: 'defaultLowerBodyColour',
  body: 'defaultUpperBodyColour',
  accessories: 'defaultAccessoriesColour',
  rightArm: 'defaultUpperBodyColour',
  rightHand: 'defaultSkinColour',
  eyes: 'defaultEyesColour',
  hair: 'defaultHairColour',
  hat: 'defaultHatColour',
};

export interface ColorMapping {
  [prop: string]: string,
};

export const loadAvatar = async (url: string): Promise<Easeljs.Container> => {
  const colors: any = {};
  const spriteSheetData: {
    [propName: string]: Groovy.SpriteSheetData;
  } = {};

  await fetchJSON(url)
    .then(async (data: any) => {
      for (const key of Object.keys(data)) {
        if (key.indexOf('Layer') !== -1) {
          const imageElements = await loadImages(data[key].images) as Array<HTMLImageElement | Error>;
          spriteSheetData[key] = {
            ...data[key],
            images: [...imageElements],
          };
        }
        else if (key.indexOf('default') === 0) {
          colors[key] = parseInt(data[key].replace('#', '0x'), 16);
        }
      }
    });

  const promises: Array<Promise<[string, HTMLCanvasElement]>> = [];
  Object.entries(spriteSheetData).forEach((entry: any) => {
    const [key, value,] = entry;
    const layer: string = key.substring(0, key.indexOf('Layer'));
    const color: number = colors[colorMapping[layer]];
    promises.push(colorizeCanvas(value.images[0], layer, color));
  });

  await Promise.all(promises)
    .then(values => {
      values.forEach((value: [string, HTMLCanvasElement]) => {
        const [layer, canvas,] = value;
        // console.log(layer, canvas);
        const key = `${layer}Layer`;
        spriteSheetData[key].images[0] = canvas;
        spriteSheetData[layer] = new createjs.SpriteSheet(spriteSheetData[key]);
      });
    });

  const container: Easeljs.Container = new createjs.Container();
  Object.keys(colorMapping).forEach((key: string) => {
    const sheet = spriteSheetData[key];
    if (typeof sheet !== 'undefined') {
      container.addChild(new createjs.Sprite(spriteSheetData[key]));
    }
  });
  return Promise.resolve(container);
};
