import React, { SyntheticEvent } from 'react';

export const BottomMenu = (props: { theme: string, lang: string, buttonAction: (c: string) => void }) => {
  const suffix = `${props.theme}-${props.lang}`

  return (
    <div id="bottommenu">
      <div id="transport" className={suffix}>
        <div onClick={(e: SyntheticEvent) => { props.buttonAction('rewind'); }} id="rewind" className={suffix}></div>
        <div onClick={(e: SyntheticEvent) => { props.buttonAction('play'); }} id="play" className={suffix}></div>
        <div onClick={(e: SyntheticEvent) => { props.buttonAction('stop'); }} id="stop" className={suffix}></div>
      </div>
      <div id="pointer"></div>
    </div >
  );
};

export default BottomMenu;

