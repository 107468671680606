const hexToRGB = (color: number): [number, number, number] => {
  const r = color >> 16;
  const g = color >> 8 & 0xFF;
  const b = color & 0xFF;
  // console.log(r, g, b)
  return [r, g, b];
};

const colorizeCanvas = (img: HTMLImageElement, layer: string, color: number): Promise<[string, HTMLCanvasElement]> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
    const {
      width,
      height,
    } = img;
    // console.log(img, layer, color, width, height);
    canvas.width = width;
    canvas.height = height;

    if (context !== null) {
      context.drawImage(img, 0, 0);
      const imageData = context.getImageData(0, 0, img.width, img.height).data;
      const worker = new Worker('../worker.js');
      worker.addEventListener('message', (data) => {
        var newImageData = context.createImageData(width, height);
        newImageData.data.set(new Uint8ClampedArray(data.data.imageData));
        context.putImageData(newImageData, 0, 0);
        resolve([layer, canvas]);
      });
      worker.postMessage({
        imageData,
        layerColour: hexToRGB(color),
      }, [imageData.buffer]);
    } else {
      reject(new Error('could not get context from canvas'));
    }
  })
}

export {
  hexToRGB,
  colorizeCanvas,
};