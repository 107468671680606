import { createAvatarUI } from './avatar_ui';
import { handleAvatarAnimationSubscription, handleAvatarSubscription } from '../subscriptions/avatar_subscriptions';
import { handlePlayheadPositionXSubscription, handleSongControlSubscription, handleSongPositionFromXSubscription } from '../subscriptions/song_subscriptions';
import { handleThumbsSubscription } from '../subscriptions/userinteraction_subscriptions';
import { handleResizeSubscription } from '../subscriptions/resize_subscriptions';
import getStore from '../../redux/store';
import { SongState } from '../../redux/reducers/song_reducer';

export const createAvatar = () => {
  const store = getStore();
  const avatar = createAvatarUI();

  handleResizeSubscription((data) => {
    const songState: SongState = store.getState().song;
    const {
      keyEditor
    } = songState;

    if (keyEditor !== null) {
      const x = keyEditor.getPlayheadX();
      avatar.handleResize(data.height, x);
    }
  });

  handleAvatarAnimationSubscription(animation => {
    avatar.setAnimation(animation);
  })

  handleAvatarSubscription(async (url, animation) => {
    await avatar.load(url);
    avatar.setAnimation(animation);
  })

  handlePlayheadPositionXSubscription((playheadPosition) => {
    avatar.setPositionX(playheadPosition);
  })

  handleSongControlSubscription(transport => {
    let anim = 'avatarEditor';
    if (transport === 'play') {
      anim = 'walk_loop';
    } else if (transport === 'stop' || transport === 'rewind') {
      anim = 'stand_loop'
    }
    avatar.setAnimation(anim);
  })

  handleSongPositionFromXSubscription(x => {
    if (avatar.isDragging() === false) {
      avatar.setPositionX(x);
    }
  })

  return {
    getContainer: avatar.getContainer
  }
}

export default createAvatar;
