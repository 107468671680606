import {
  STAGE_INITIALIZED,
} from '../actions/app_actions';
import {
  SEQUENCER_READY,
  LOAD_INSTRUMENT,
  PARSE_INSTRUMENT,
  INSTRUMENT_LOADED,
  AVATAR_LOADED,
} from '../actions/load_actions';
import { Easeljs } from 'src/easeljs';
import { AnyAction } from 'redux';
import { createjs } from '../../easeljs/app';
import { Groovy } from '../../types';

export interface AppState {
  stage: Easeljs.Stage
  origWidth: number
  origHeight: number
  keyeditorMargins: { left: number[], right: number[] }
  keyeditorMarginLeft: number
  keyeditorMarginRight: number
  width: number
  height: number
  scale: number
  topMenuHeight: number
  bottomMenuHeight: number
  canvasHeight: number
  canvasWidth: number
  canvasMargin: number
  mouseX: number
  mouseY: number
  loadingMessage: string
  loadingPercentage: number
  songPartsMenu: null | Heartbeat.Song
  lang: string
  theme: string
  instruments: {
    [id: string]: {
      translationId: string
      revamount: number
      srcname: string
      release: number
      group: string
    }
  }
  spriteAnimations: {
    [T: string]: null | Groovy.SpriteAnimation,
  }
  partIconScale: number
}

export const appInitialState: AppState = {
  stage: new createjs.Stage('dummy'),
  origWidth: window.innerWidth,
  origHeight: window.innerHeight,
  width: window.innerWidth,
  height: window.innerHeight,
  scale: 1,
  topMenuHeight: 0,
  bottomMenuHeight: 0,
  canvasHeight: 0,
  canvasWidth: 0,
  canvasMargin: 2,
  keyeditorMarginLeft: 120,
  keyeditorMarginRight: 80,
  keyeditorMargins: { left: [95, 120], right: [60, 80] },
  mouseX: 0,
  mouseY: 0,
  loadingMessage: 'loading...',
  loadingPercentage: 100,
  songPartsMenu: null,
  theme: 'shapes',
  lang: 'en-us',
  instruments: {},
  partIconScale: 1,
  spriteAnimations: {
    A: null,
    B: null,
    C: null,
    M: null,
    R: null,
  }
};

export const appReducer = (state: AppState = appInitialState, action: AnyAction): AppState => {
  if (action.type === SEQUENCER_READY) {
    const spriteAnimations = {
      A: action.payload.arpeggios,
      B: action.payload.bass,
      C: action.payload.chords,
      M: action.payload.melody,
      R: action.payload.rhythms,
    }
    return {
      ...state,
      spriteAnimations,
      songPartsMenu: action.payload.song,
      instruments: action.payload.instruments,
      loadingMessage: 'loading avatar',
    };
  } else if (action.type === AVATAR_LOADED) {
    return {
      ...state,
      loadingMessage: '',
    }
  } else if (action.type === STAGE_INITIALIZED) {
    return {
      ...state,
      stage: action.payload.stage,
    }
  } else if (action.type === LOAD_INSTRUMENT) {
    return {
      ...state,
      loadingMessage: 'loading instrument',
    }
  } else if (action.type === PARSE_INSTRUMENT) {
    return {
      ...state,
      loadingMessage: 'parsing instrument',
    }
  } else if (action.type === INSTRUMENT_LOADED) {
    return {
      ...state,
      loadingMessage: '',
    }
  }
  return state;
}
