import { Easeljs } from '../easeljs';
import { stageInitialized } from '../redux/actions/app_actions';
import getStore, { getState$ } from '../redux/store';
import createArranger from './arranger/arranger_index';
import createAvatar from './avatar/avatar_index';
import createPartMenu from './partmenu/partmenu_index';
import { createResizeObservables, handleResizeSubscription } from './subscriptions/resize_subscriptions';
import { createSongObservables, handleSongControlSubscription, handleSongPositionFromXSubscription } from './subscriptions/song_subscriptions';
import { createMenuObservers } from './subscriptions/menu_subscriptions';
import { createAvatarObservers } from './subscriptions/avatar_subscriptions';

// @ts-ignore
export const createjs: any = window.createjs;
// import c from '../lib/createjs.min';
// export const createjs: any = c;

const createEaseljsApp = async () => {
  const store = getStore();
  const state$ = getState$();

  createResizeObservables(state$, store);
  createSongObservables(state$, store);
  createMenuObservers(state$);
  createAvatarObservers(state$);

  const stage: Easeljs.Stage = new createjs.Stage('groovy');
  createjs.Touch.enable(stage);
  // stage.enableMouseOver(10);
  const background: Easeljs.Shape = new createjs.Shape();
  const graphics: Easeljs.Graphics = new createjs.Graphics();
  const canvas = stage.canvas as HTMLCanvasElement;
  background.graphics = graphics;
  stage.addChild(background);
  store.dispatch(stageInitialized(stage));

  // continuously update stage
  const updateStage = () => {
    stage.update();
    // console.log('autoupdate stage')
  }
  createjs.Ticker.timingMode = createjs.Ticker.RAF_SYNCHED;
  createjs.Ticker.framerate = 30;
  createjs.Ticker.addEventListener('tick', updateStage);


  // document.addEventListener('mousemove', (e) => {
  //   const div = document.getElementById('pointer');
  //   if (div) {
  //     div.innerHTML = `<b>${e.clientX} - ${e.clientY}</b>`;
  //   }
  // })

  // create ui elements
  const arranger = createArranger();
  const avatar = createAvatar();
  const partMenu = createPartMenu();
  arranger.addAvatar(avatar.getContainer());

  const resize = (w: number, h: number) => {
    canvas.width = w;
    canvas.height = h;
    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;
    // graphics.clear();
    // graphics.beginFill('#fff000');
    // graphics.drawRect(0, 0, w, h);
    // graphics.endFill()
  }

  handleResizeSubscription((data) => {
    resize(data.width, data.height);
  })
}

export default createEaseljsApp;

