import { Easeljs } from '../../easeljs';

export const LOADING = 'LOADING';
export const STAGE_INITIALIZED = 'STAGE_INITIALIZED';
export const DATA_RECEIVED = 'DATA_RECEIVED';
export const RESIZED = 'RESIZED';


export const resize = (width: number, height: number, topMenuHeight: number, bottomMenuHeight: number) => ({
  type: RESIZED,
  payload: {
    width,
    height,
    topMenuHeight,
    bottomMenuHeight,
  }
});

export const stageInitialized = (stage: Easeljs.Stage) => ({
  type: STAGE_INITIALIZED,
  payload: {
    stage,
  }
});


