export const SELECT_TOPMENU_ITEM = 'SELECT_TOPMENU_ITEM';
export const PART_MENU_HIDDEN = 'PART_MENU_HIDDEN';
export const PART_MENU_BUTTON_DOWN = 'PART_MENU_BUTTON_DOWN';
export const PART_MENU_BUTTON_UP = 'PART_MENU_BUTTON_UP';
export const PART_MENU_SCROLL = 'PART_MENU_SCROLL';
export const PART_MENU_MAX_SCROLL = 'PART_MENU_MAX_SCROLL';

export const selectTopMenuItem = (selected: string) => ({
  type: SELECT_TOPMENU_ITEM,
  payload: {
    selected,
  }
});

export const partMenuScroll = (direction: string) => ({
  type: PART_MENU_SCROLL,
  payload: {
    direction,
  }
})

export const partmenuSliderMaxScroll = (flag: boolean) => ({
  type: PART_MENU_MAX_SCROLL,
  payload: {
    flag,
  }
})

export const onPartMenuHidden = () => ({
  type: PART_MENU_HIDDEN
});

export const partMenuButtonDown = (trackName: string) => ({
  type: PART_MENU_BUTTON_DOWN,
  payload: {
    trackName,
  }
})

export const partMenuButtonUp = () => ({
  type: PART_MENU_BUTTON_UP
})
