import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map, pluck } from 'rxjs/operators';
import { AppStateAll } from '../../types';

let animation$: Observable<string> | null = null;
let url$: Observable<[string, string]> | null = null;

const createAvatarObservers = (state$: Observable<AppStateAll>) => {
  animation$ = state$.pipe(
    // distinctUntilChanged(),
    // distinctUntilKeyChanged('avatar'),
    pluck('avatar'),
    distinctUntilKeyChanged('animation'),
    map(app => app.animation)
  );

  url$ = state$.pipe(
    // distinctUntilChanged(),
    // distinctUntilKeyChanged('avatar'),
    pluck('avatar'),
    distinctUntilKeyChanged('url'),
    map(app => [app.url, app.animation])
  );
}

const handleAvatarAnimationSubscription = (callback: (a: string) => void) => {
  if (animation$ !== null) {
    animation$.subscribe(animation => {
      callback(animation);
    })
  }
}

const handleAvatarSubscription = (callback: (url: string, animation: string) => void) => {
  if (url$ !== null) {
    url$.subscribe((data) => {
      callback(data[0], data[1])
    })
  }
}

export { createAvatarObservers, handleAvatarSubscription, handleAvatarAnimationSubscription };

