import createSongWrapper from './song_wrapper';
import { handleResizeSubscription } from '../subscriptions/resize_subscriptions';
import {
  handleSongControlSubscription,
  handleSongPositionFromXSubscription,
  handlePageoffsetSubscription,
  handleScoreNavigationSubscription,
  handleSelectedPartSubscription,
  handleNewPartSubscription,
  songRemovePart,
} from '../subscriptions/song_subscriptions';
import {
} from '../subscriptions/menu_subscriptions';
import { SpriteAnimationController } from '../animations/sprite_animation_controller';


const createArranger = () => {
  const songWrapper = createSongWrapper();
  const grid = songWrapper.getGrid();

  songRemovePart(songWrapper.removePart)
  handleResizeSubscription(grid.resize)
  handleSongControlSubscription(songWrapper.controlSong)
  handleSongPositionFromXSubscription(grid.setPlayheadToX)
  handlePageoffsetSubscription(grid.setPageoffset)
  handleSelectedPartSubscription(songWrapper.movePart)

  handleNewPartSubscription((data, sprite) => {
    const { trackName, numNotes, scale } = data;
    const animController = new SpriteAnimationController(sprite)
    animController.trackName = trackName;
    animController.scale = scale;
    animController.configure(numNotes);
    songWrapper.addPart(animController, data);
    grid.addPendingPart(animController, data);
  })


  return {
    getContainer: grid.getContainer,
    addAvatar: grid.addAvatar,
  }
}

export default createArranger;
