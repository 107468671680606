import { TweenMax } from 'gsap';
import { createjs } from '../app'
import {
  SpriteAnimationController,
  SpriteAnimationControllerArpeggios,
  SpriteAnimationControllerRhythms
} from '../animations/sprite_animation_controller';
import { Easeljs } from '../../easeljs';
import createSlider, { Slider } from './partmenu_slider';
import {
  onPartMenuHidden,
  partmenuSliderMaxScroll
} from '../../redux/actions/menu_actions';
import { Store, AnyAction } from 'redux';
import { AppStateAll } from '../../types';
import { SwipeData, SwipeEvent } from '../../util/swipe';

const types: Array<string> = [
  'A',
  'B',
  'C',
  'M',
  'R',
];
const sliders: { [id: string]: Slider } = {};

const createMenu = (store: Store<AppStateAll, AnyAction>) => {
  const stage = store.getState().app.stage;
  const container: Easeljs.Container = new createjs.Container();
  const coverLeft: Easeljs.Shape = new createjs.Shape();
  const coverRight: Easeljs.Shape = new createjs.Shape();
  stage.addChild(container);
  let {
    spriteAnimations,
    songPartsMenu,
    keyeditorMarginLeft,
    canvasWidth
  } = store.getState().app;
  let currentSlider: Slider | null = null;
  let minPosXSlider: number = 0;

  types.forEach(type => {
    if (songPartsMenu !== null) {
      const b = songPartsMenu.tracks.filter(t => t.name.startsWith(type));
      const sprite = spriteAnimations[type];
      switch (type) {
        // case 'A':
        //   if (sprite !== null) {
        //     sliders[type] = createSlider(b, SpriteAnimationControllerArpeggios, sprite, 10, 50, keyeditorMarginLeft);
        //   }
        //   break;
        case 'B':
          if (sprite !== null) {
            sliders[type] = createSlider(b, SpriteAnimationController, sprite, 10, 40, keyeditorMarginLeft);
          }
          break;
        case 'C':
          if (sprite !== null) {
            sliders[type] = createSlider(b, SpriteAnimationController, sprite, 10, 50, keyeditorMarginLeft);
          }
          break;
        case 'M':
          if (sprite !== null) {
            sliders[type] = createSlider(b, SpriteAnimationController, sprite, 10, 50, keyeditorMarginLeft);
          }
          break;
        // case 'R':
        //   if (sprite !== null) {
        //     sliders[type] = createSlider(b, SpriteAnimationControllerRhythms, sprite, 10, 50, keyeditorMarginLeft);
        //   }
        //   break;
      }
    }
  });

  const drawCovers = (width: number, height: number) => {
    coverLeft.graphics.clear();
    coverLeft.graphics.beginFill('#9cbcc4');
    // coverLeft.graphics.beginFill('#00ff00');
    coverLeft.graphics.drawRect(0, 0, 50, height);
    coverRight.graphics.clear();
    coverRight.graphics.beginFill('#9cbcc4');
    // coverRight.graphics.beginFill('#ff0000');
    coverRight.graphics.drawRect(width - 50, 0, 50, height);
  }

  const setScale = (partIconScale: number, keyeditorMarginLeft: number, _canvasWidth: number) => {
    canvasWidth = _canvasWidth;
    Object.values(sliders).forEach(s => {
      s.setScale(partIconScale, keyeditorMarginLeft);
      s.setPositionX(0)
    })
    const h = partIconScale * 90;
    drawCovers(canvasWidth, h);
  }

  const resize = (_canvasWidth: number, _canvasHeigth: number, partIconScale: number) => {
    canvasWidth = _canvasWidth;
    // Object.values(sliders).forEach(s => {
    //   s.adjustTreshHoldY(_canvasHeigth)
    // })

    const h = partIconScale * 90;
    drawCovers(canvasWidth, h);
    if (currentSlider !== null) {
      minPosXSlider = -currentSlider.getWidth() + canvasWidth;
      if (currentSlider.container.x > minPosXSlider) {
        store.dispatch(partmenuSliderMaxScroll(false))
      }
    }
  }

  const setMenu = (type: string) => {
    container.removeAllChildren();
    currentSlider = sliders[type.substring(0, 1).toUpperCase()]
    if (typeof currentSlider !== 'undefined') {
      currentSlider.container.x = 0;
      container.addChild(currentSlider.container);
      container.addChild(coverLeft);
      container.addChild(coverRight);
      minPosXSlider = -currentSlider.getWidth() + canvasWidth;
    } else {
      currentSlider = null;
    }
    // console.log('setMenu', currentSlider);
  }

  const show = () => {
    TweenMax.fromTo(container, 0.3, { y: -100 }, { y: 0 });
    // document.addEventListener('swipe', scroll)
    // if (currentSlider !== null) {
    //   currentSlider.container.addEventListener('mousedown', scroll2)
    // }
  }

  const hide = () => {
    TweenMax.fromTo(container, 0.3, { y: 0 }, {
      y: -100, onComplete: () => {
        store.dispatch(onPartMenuHidden());
        // currentSlider = null; => WEIRD STUFF!
      }
    });
    // document.removeEventListener('swipe', scroll)
    // if (currentSlider !== null) {
    //   currentSlider.container.removeEventListener('mousedown', scroll2)
    // }
    // document.removeEventListener('mousedown', scroll2)
  }

  // const scroll: EventListener = (e: SwipeEvent) => {
  //   const data: SwipeData = e.detail;
  //   scrollSlider(data.direction)
  // }

  const scroll2 = (o: Object) => {
    const e = o as Easeljs.MouseEvent
    const te = e.nativeEvent as TouchEvent
    const me = e.nativeEvent as MouseEvent
    let x = 0;
    if (typeof te.changedTouches === 'undefined') {
      x = me.clientX;
    } else {
      x = te.changedTouches[0].clientX;
    }
    if (x > canvasWidth / 2) {
      scrollSlider('left')
    } else {
      scrollSlider('right')
    }
  }

  const scrollSlider = (direction: string) => {
    // console.log(direction);
    if (currentSlider === null) {
      return;
    }
    if (direction === 'left') {
      const newX = Math.max(minPosXSlider, currentSlider.container.x - canvasWidth / 4)
      TweenMax.fromTo(currentSlider.container, 0.6, { x: container.x }, { x: newX });
    } else if (direction === 'right') {
      const newX = Math.min(0, container.x + canvasWidth / 4)
      TweenMax.fromTo(currentSlider.container, 0.6, { x: currentSlider.container.x }, { x: newX });
    }
  }

  const scroll = (count: number) => {
    if (currentSlider === null) {
      return;
    }
    let newX = -count * (canvasWidth / 4)
    if (newX > 0) {
      newX = 0;
    } else if (newX < minPosXSlider) {
      newX = minPosXSlider;
      store.dispatch(partmenuSliderMaxScroll(true))
    }
    const target = currentSlider.container;
    TweenMax.fromTo(target, 0.6, { x: target.x }, { x: newX });
  }

  return {
    setScale,
    resize,
    setMenu,
    show,
    hide,
    scroll,
  }
}

export default createMenu;