import { AnyAction } from 'redux';
import {
  UPDATE_SONG,
  SONG_TRANSPORT,
  UPDATE_SONG_POSITION_TICKS,
  SET_PAGE_OFFSET_X,
  SET_SONG_POSITION_FROM_X,
  UPDATE_KEY_EDITOR,
  SCORE_NAVIGATION,
  ADD_PART_TO_SONG,
  START_DRAG_PART,
  STOP_DRAG_PART,
  DRAG_PART,
  REMOVE_PART_FROM_SONG,
  PART_REMOVED_FROM_SONG,
} from '../actions/song_actions';
import { Groovy } from '../../types';

export type SongState = {
  song: null | Heartbeat.Song
  songPosition: null | Heartbeat.SongPosition
  songPositionTicks: number
  keyEditor: null | Heartbeat.KeyEditor
  transport: string
  barsPerPage: number
  totalBars: number
  playheadPositionX: number
  pageOffsetX: number
  songPositionFromX: number // song position changes by dragging the avatar or clicking on stage
  startBar: number
  barWidth: number
  scoreWidth: number
  minBarWidth: number
  scoreNavigation: string
  newPart: Groovy.NewPart | null
  partsOnStage: Groovy.PartController[]
  selectedPart: Groovy.SelectedPart
  removePart: string
}

export const songInitialState: SongState = {
  song: null,
  songPosition: null,
  songPositionTicks: 0,
  transport: 'stop',
  barsPerPage: 3,
  totalBars: 6,
  playheadPositionX: 0,
  pageOffsetX: 0,
  keyEditor: null,
  songPositionFromX: 0,
  startBar: 0,
  barWidth: 0,
  scoreWidth: 0,
  minBarWidth: 200,
  scoreNavigation: '',
  newPart: null,
  partsOnStage: [],
  selectedPart: {
    action: 'stop',
    id: '',
    x: 0,
    y: 0,
  },
  removePart: '',
};

export const songReducer = (state: SongState = songInitialState, action: AnyAction): SongState => {
  if (action.type === UPDATE_SONG) {
    return {
      ...state,
      song: action.payload.song,
    }

  } else if (action.type === UPDATE_SONG_POSITION_TICKS) {
    const { payload: { ticks } } = action;
    // const x: number = state.keyEditor !== null ? state.keyEditor.ticksToX(ticks) : state.playheadPosition;
    const x: number = state.keyEditor !== null ? state.keyEditor.getPlayheadX() : state.playheadPositionX;
    const pageOffsetX = (Math.floor(x / state.scoreWidth) * state.scoreWidth) + (state.startBar * state.barWidth);
    return {
      ...state,
      pageOffsetX,
      playheadPositionX: x,
      songPositionTicks: ticks,
    }

  } else if (action.type === SONG_TRANSPORT) {
    let { playheadPositionX: playheadPosition, pageOffsetX, startBar } = state;
    if (action.payload.transport === 'rewind') {
      playheadPosition = 0;
      pageOffsetX = 0;
      startBar = 0;
    }
    return {
      ...state,
      startBar,
      pageOffsetX,
      playheadPositionX: playheadPosition,
      transport: action.payload.transport,
    }

  } else if (action.type === SET_SONG_POSITION_FROM_X) {
    return {
      ...state,
      songPositionFromX: action.payload.x,
    }

  } else if (action.type === SET_PAGE_OFFSET_X) {
    let startBar = state.startBar + state.barsPerPage;
    startBar = Math.min(state.totalBars - state.barsPerPage, startBar);
    // console.log('PAGE OFFSET X', action.payload.offset, startBar);
    return {
      ...state,
      startBar,
      pageOffsetX: action.payload.offset,
    }

  } else if (action.type === UPDATE_KEY_EDITOR) {
    return {
      ...state,
      keyEditor: action.payload.keyEditor,
    }

  } else if (action.type === SCORE_NAVIGATION) {
    const { payload: { command } } = action;
    let { startBar, totalBars, barsPerPage, barWidth } = state;
    if (command === 'next_page') {
      startBar += barsPerPage;
      startBar = Math.min(totalBars - barsPerPage, startBar);
    } else if (command === 'prev_page') {
      startBar -= barsPerPage;
      startBar = Math.max(0, startBar);
    } else if (command === 'next_bar') {
      startBar++;
      startBar = Math.min(totalBars - barsPerPage, startBar);
    } else if (command === 'prev_bar') {
      startBar--;
      startBar = Math.max(0, startBar);
    }
    const pageOffsetX = startBar * barWidth;
    // console.log('NAV', startBar, barWidth);
    // const songPositionTicks: number = state.keyEditor !== null ? state.keyEditor.xToTicks(pageOffsetX) : state.songPositionTicks;

    return {
      ...state,
      startBar,
      pageOffsetX,
      songPositionFromX: pageOffsetX,
      scoreNavigation: action.payload.command,
    }

  } else if (action.type === ADD_PART_TO_SONG) {
    return {
      ...state,
      newPart: { ...action.payload },
    }
  } else if (action.type === REMOVE_PART_FROM_SONG) {
    return {
      ...state,
      removePart: action.payload.partId,
    }
  } else if (action.type === PART_REMOVED_FROM_SONG) {
    return {
      ...state,
      removePart: '',
    }
  }

  else if (action.type === START_DRAG_PART) {
    return {
      ...state,
      selectedPart: {
        ...action.payload,
        action: 'start',
      },
    }
  } else if (action.type === STOP_DRAG_PART) {
    return {
      ...state,
      selectedPart: {
        ...state.selectedPart,
        id: action.payload.id as string,
        action: 'stop',
      }
    }
  } else if (action.type === DRAG_PART) {
    // console.log(action.payload.x, action.payload.y);
    const selectedPart = {
      id: action.payload.id,
      x: action.payload.x,
      y: action.payload.y,
      action: 'move',
    }
    return {
      ...state,
      selectedPart,
    }
  }
  return state;
}
