// credits: http://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android/23230280#23230280

export type SwipeData = { [id: string]: string }
export interface SwipeEvent extends Event { detail: SwipeData }


let xDown: number// = null
let yDown: number// = null
let lastEvent: TouchEvent// = null
const mAbs = Math.abs
const minDistance = 100


const createEvent = function (type: string, detail: { [id: string]: string }) {
  document.dispatchEvent(new CustomEvent(type, { detail }))
}

const Swipe = {
  init() {
    document.addEventListener('touchstart', this.handleTouchStart, false)
    document.addEventListener('touchmove', this.handleTouchMove, false)
    document.addEventListener('touchend', this.handleTouchEnd, false)
  },

  handleTouchStart(evt: TouchEvent) {
    //evt.preventDefault()
    xDown = evt.touches[0].clientX
    yDown = evt.touches[0].clientY
  },

  handleTouchMove(evt: TouchEvent) {
    // evt.defaultPrevented
    // evt.preventDefault() // this also prevents zooming!
    lastEvent = evt
  },

  handleTouchEnd() {
    //evt.preventDefault()

    if (typeof lastEvent === 'undefined' || lastEvent.touches.length === 0) {
      return
    }

    let xUp = lastEvent.touches[0].clientX
    let yUp = lastEvent.touches[0].clientY

    let xDiff = xDown - xUp
    let yDiff = yDown - yUp

    if (mAbs(xDiff) > mAbs(yDiff)) {
      if (xDiff > minDistance) {
        createEvent('swipe', { direction: 'left' })
      } else if (xDiff < -minDistance) {
        createEvent('swipe', { direction: 'right' })
      }
    } else if (mAbs(xDiff) <= mAbs(yDiff)) {
      if (yDiff > minDistance) {
        createEvent('swipe', { direction: 'up' })
      } else if (yDiff < -minDistance) {
        createEvent('swipe', { direction: 'down' })
      }
    }

    /* reset values */
    // xDown = null
    // yDown = null
    // lastEvent = null
  }
}

export default Swipe

