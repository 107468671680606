import { CHANGE_ANIMATION, CHANGE_AVATAR } from '../actions/avatar_actions';
import { Reducer, AnyAction } from 'redux';

export interface AvatarState {
  urls: string[];
  url: string,
  animation: string,
  animations: string[],
}


const animations = [
  'cheer',
  'point_up_right',
  'walk_loop',
  'turn_profile_to_straight',
  'stand_smile_loop',
  'turn_straight_to_profile',
  'stand_loop',
  'stop_speak',
  'stand_loop_straight',
  'point_up_left',
  'avatarEditor',
  'walk_smile_loop',
  'point_up_left_smile',
  'speak',
];

const urls = [
  '/assets/avatars/Shapes-Black-girl.json',
  '/assets/avatars/Shapes-Girl-with-blue-hair.json',
  '/assets/avatars/Shapes-Girl-with-dark-blue-hair.json',
  '/assets/avatars/Shapes-Blond-white-girl.json',
  '/assets/avatars/Shapes-Black-boy.json',
  '/assets/avatars/Shapes-Boy-with-glasses.json',
  '/assets/avatars/Shapes-Boy-with-hat.json',
  '/assets/avatars/Shapes-Boy-with-brown-hair.json',
];

export const avatarInitialState: AvatarState = {
  animations,
  urls,
  url: urls[2],
  animation: 'avatarEditor',
};

export const avatarReducer = (state: AvatarState = avatarInitialState, action: AnyAction): AvatarState => {
  if (action.type === CHANGE_AVATAR) {
    return {
      ...state,
      url: action.payload.url,
    };
  } else if (action.type === CHANGE_ANIMATION) {
    return {
      ...state,
      animation: action.payload.animation,
    };
  }

  return state;
}
