import React, { Fragment } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from './Loader';
import TopMenu from './TopMenu';
import BottomMenu from './BottomMenu';
import ScoreNavigationMenu from './ScoreNavigationMenu';
import { AppStateAll, Groovy } from '../types';
import { getButtonsTopMenu } from '../redux/topmenu_selector';
import { selectTopMenuItem, partMenuScroll } from '../redux/actions/menu_actions';
import { songTransport, scoreNavigation } from '../redux/actions/song_actions';
import PartMenuSliderScrollButtons from './PartMenuSliderScrollButtons';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    onSelectTopMenu: selectTopMenuItem,
    songTransport: songTransport,
    scoreNavigation: scoreNavigation,
    partMenuScroll: partMenuScroll,
  }, dispatch);

const mapStateToProps = (state: AppStateAll) => ({
  buttonsTopMenu: getButtonsTopMenu(state),
  selected: state.menu.selectedTopMenuItem,
  loadingMessage: state.app.loadingMessage,
  loadingPercentage: state.app.loadingPercentage,
  lang: state.app.lang,
  theme: state.app.theme,
  partMenuMaxScroll: state.menu.partMenuMaxScroll,
  partMenuCurrentScroll: state.menu.partMenuScroll,
  showPageNavigationMenu: state.menu.selectedTopMenuItem !== 'melody'
    && state.menu.selectedTopMenuItem !== 'bass'
    && state.menu.selectedTopMenuItem !== 'chord',
})

type Props = {
  buttonsTopMenu: Array<[string, string]>,
  selected: string,
  loadingMessage: string,
  loadingPercentage: number,
  onSelectTopMenu: (m: string) => any
  songTransport: (m: string) => void
  scoreNavigation: (command: string) => void
  partMenuScroll: (direction: string) => void
  partMenuMaxScroll: boolean
  partMenuCurrentScroll: number
  lang: string
  theme: string
  showPageNavigationMenu: boolean
}

const App = (props: Props): JSX.Element => {
  return (
    <Fragment>
      {props.loadingMessage && <Loader message={props.loadingMessage} percentage={props.loadingPercentage}></Loader>}
      <TopMenu
        buttons={props.buttonsTopMenu}
        onClick={props.onSelectTopMenu}
      ></TopMenu>
      <ScoreNavigationMenu
        onClick={props.scoreNavigation}
        show={props.showPageNavigationMenu}
        className={`${props.theme}-${props.lang}`}
      />
      <PartMenuSliderScrollButtons
        onClick={props.partMenuScroll}
        maxScroll={props.partMenuMaxScroll}
        currentScroll={props.partMenuCurrentScroll}
        show={!props.showPageNavigationMenu}
        className={`${props.theme}-${props.lang}`}
      />
      <canvas id="groovy"></canvas>
      <BottomMenu theme={props.theme} lang={props.lang} buttonAction={props.songTransport}></BottomMenu>
    </Fragment >
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
