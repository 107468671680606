import sequencer from 'heartbeat-sequencer';
import { Store } from 'redux';
import { Easeljs } from '../../easeljs';
import { Groovy, AppStateAll } from '../../types';
import getStore from '../../redux/store';
import { startDragPart, stopDragPart, dragPart } from '../../redux/actions/song_actions';


class PartController {
  private _id: string
  get id(): string {
    return this._id;
  }
  get x(): number {
    return this.container.x
  }
  get y(): number {
    return this.container.y
  }
  private _ticks: number = 0
  set ticks(ticks: number) {
    this._ticks = ticks;
  }
  get ticks(): number {
    return this._ticks;
  }
  private _pitch: number = 0
  set pitch(pitch: number) {
    this._pitch = pitch;
  }
  get pitch(): number {
    return this._pitch;
  }
  private animController: Groovy.SpriteAnimationController
  private container: Easeljs.Container
  private store: Store<AppStateAll>
  private stage: Easeljs.Stage
  private canvas: HTMLCanvasElement
  private touchEventIdentifier: number = -1
  private boundMouseMove: (e: MouseEvent) => void
  private boundTouchMove: (e: TouchEvent) => void
  private boundDown: (o: Object) => void
  private boundUp: (o: Object) => void

  constructor(animController: Groovy.SpriteAnimationController) {
    this.store = getStore();
    ({ app: { stage: this.stage } } = this.store.getState());
    this.canvas = this.stage.canvas as HTMLCanvasElement;
    this.animController = animController;
    this.container = animController.container;
    this._id = animController.id;

    this.boundUp = this.up.bind(this);
    this.boundDown = this.down.bind(this);
    this.boundMouseMove = this.moveMouse.bind(this);
    this.boundTouchMove = this.moveTouch.bind(this);
    this.container.on('mousedown', this.boundDown);
    // this.container.on('click', this.boundUp);
    this.canvas.addEventListener('mouseup', this.boundUp);
    this.canvas.addEventListener('touchend', this.boundUp);
  }

  private move(x: number, y: number) {
    this.store.dispatch(dragPart(this.id, x, y));
  }

  private moveMouse(e: MouseEvent) {
    const x = e.clientX;
    const y = e.clientY;
    this.move(x, y);
  }

  private moveTouch(e: TouchEvent) {
    // const current = e.changedTouches[0];
    let current: Touch | null = null;
    for (let i = 0; i < e.changedTouches.length; i++) {
      const tmp = e.changedTouches[0];
      if (tmp.identifier === this.touchEventIdentifier) {
        current = tmp;
        break;
      }
    }
    if (current !== null) {
      const x = current.clientX;
      const y = current.clientY;
      this.move(x, y);
    }
  }

  private up() {
    this.store.dispatch(stopDragPart(this.id));
    this.canvas.removeEventListener('mousemove', this.boundMouseMove);
    this.canvas.removeEventListener('touchmove', this.boundTouchMove);
  }

  private down(o: Object) {
    const e = o as Easeljs.MouseEvent
    const ne = e.nativeEvent;
    const te = ne as TouchEvent;
    const me = ne as MouseEvent;
    let x = 0;
    let y = 0;
    if (te.changedTouches) {
      const e = te.changedTouches[0];
      this.touchEventIdentifier = e.identifier;
      x = e.clientX;
      y = e.clientY;
      this.canvas.addEventListener('touchmove', this.boundTouchMove);
    } else {
      x = me.clientX;
      y = me.clientY;
      this.canvas.addEventListener('mousemove', this.boundMouseMove);
      // console.log(this.thumb, me);
    }
    this.store.dispatch(startDragPart(this.id, x, y));
  }

  setPosition(x: number, y: number) {
    this.container.x = x;
    this.container.y = y;
  }

  startDrag(x: number, y: number, touchEventIdentifier: number) {
    this.store.dispatch(startDragPart(this.id, x, y));
    if (touchEventIdentifier >= 0) {
      this.touchEventIdentifier = touchEventIdentifier;
      this.canvas.addEventListener('touchmove', this.boundTouchMove);
    } else {
      this.canvas.addEventListener('mousemove', this.boundMouseMove);
    }
  }

  getContainer() {
    return this.container
  }

  getSpriteController() {
    return this.animController;
  }

  setScale(scale: number) {
    this.container.scaleX = scale;
    this.container.scaleY = scale;
  }

  getId() {
    return this.id
  }
}

export {
  PartController,
};