import { createSelector } from 'reselect'
import { AppStateAll } from '../types';
import { AppState } from './reducers/app_reducer';
import { MenuState } from './reducers/menu_reducer';
import { selectTopMenuItem } from './actions/menu_actions';

const buttonLabels = [
  'logo',
  'sfx',
  'melody',
  'bass',
  'chord',
  'arpeggio',
  'rhythm',
  'tempo',
  'instruments',
  'dynamics',
  'chromatics',
];

const getAppState = (state: AppStateAll) => state.app;
const getMenuState = (state: AppStateAll) => state.menu;

export const getButtonsTopMenu = createSelector(
  [getAppState, getMenuState],
  (app, menu) => {
    const {
      lang,
      theme,
    } = app;
    const {
      selectedTopMenuItem: selected,
    } = menu;

    const suffix = `${theme}-${lang}`
    const buttons: Array<[string, string]> = buttonLabels.map(label => {
      let className = `${label} ${suffix}`;
      if (label === selected) {
        className = `selected ${label} ${suffix}`;
      }
      return [label, className];
    });
    return buttons;
  }
)

