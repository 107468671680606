import { TweenMax } from 'gsap';
import { Easeljs } from '../../easeljs';
import { createjs } from '../app';
import { Groovy } from '../../types';
import getStore from '../../redux/store';
import { PartController } from './part_controller';
import { setSongPositionFromX, removePartFromSong, stopDragPart } from '../../redux/actions/song_actions';

const createGrid = (keyEditor: Heartbeat.KeyEditor) => {
  const store = getStore();
  const container: Easeljs.Container = new createjs.Container();
  const barLines: Easeljs.Shape = new createjs.Shape();
  const barNumbers: Easeljs.Container = new createjs.Container();
  const background: Easeljs.Shape = new createjs.Shape();
  const blGraphics: Easeljs.Graphics = new createjs.Graphics();
  const bgGraphics: Easeljs.Graphics = new createjs.Graphics();
  const partsOnStage: { [id: string]: PartController } = {}
  let scoreWidth: number = -1
  let avatar: Easeljs.Container | null = null
  let threshold: number = 0;
  barLines.graphics = blGraphics;
  background.graphics = bgGraphics;
  const { app: { stage } } = store.getState();
  let {
    app: {
      keyeditorMarginLeft,
    },
    song: {
      barWidth,
      totalBars,
      barsPerPage,
      pageOffsetX,
    }
  } = store.getState();

  container.addChild(background);
  container.addChild(barLines);
  container.addChild(barNumbers);
  container.x = keyeditorMarginLeft;
  stage.addChild(container);

  // keyEditor.addEventListener('scroll', function (data: { x: number }) {
  //   if (data.x !== pageOffsetX) {
  //     store.dispatch(setPageOffset(data.x));
  //   }
  // });

  const resize = (data: Groovy.ResizeData) => {
    const {
      scale,
      width,
      height,
      keyeditorMarginRight,
      // partsOnStage,
      partIconScale,
    } = data;
    ({ keyeditorMarginLeft, barWidth, barsPerPage } = data);
    const viewportWidth = width - keyeditorMarginLeft - keyeditorMarginRight;
    threshold = height - 50;
    scoreWidth = totalBars * barWidth;
    // console.log(scoreWidth)
    // const numPages = Math.max(totalBars / barsPerPage, 1)
    bgGraphics.clear();
    bgGraphics.beginFill('rgba(255, 255, 255, 1)')
    bgGraphics.rect(0, 0, scoreWidth, height)

    keyEditor.setViewport(viewportWidth, height);
    keyEditor.setBarsPerPage(barsPerPage);
    keyEditor.verticalLine.reset();
    keyEditor.setSnapX(8);

    barNumbers.removeAllChildren();
    blGraphics.clear();
    drawVerticalLine(1, 0, height);
    blGraphics.setStrokeStyle(1);
    while (keyEditor.verticalLine.hasNext('bar')) {
      const {
        x,
        bar,
      } = keyEditor.verticalLine.next('bar') as Heartbeat.LineData;
      drawVerticalLine(bar, x, height);
    }
    container.x = -pageOffsetX + keyeditorMarginLeft;

    Object.values(partsOnStage).forEach((p) => {
      p.setScale(partIconScale);
      const x = keyEditor.ticksToX(p.ticks);
      const y = keyEditor.pitchToY(p.pitch);
      p.setPosition(x, y);
    })
  }

  const drawVerticalLine = (bar: number, x: number, h: number) => {
    blGraphics.beginStroke('#000000');
    blGraphics.moveTo(x, 0);
    blGraphics.lineTo(x, h);
    blGraphics.endStroke();
    barNumbers.addChild(drawBarNumber(bar, x, h));
  }

  const drawBarNumber = (n: number, x: number, y: number): Easeljs.Text => {
    const text = new createjs.Text(`${n}`, '13px Arial', '#000');
    text.x = x + 3;
    text.y = y - 10;
    text.textBaseline = 'alphabetic';
    return text;
  }

  const setPlayheadToX = (x: number) => {
    keyEditor.setPlayheadToX(x);
  }

  const setPageoffset = (offset: number) => {
    pageOffsetX = offset;
    // container.x = keyeditorMarginLeft - offset;
    TweenMax.fromTo(container, 0.3, { x: container.x }, { x: keyeditorMarginLeft - offset });
  }

  const addPendingPart = (animController: Groovy.SpriteAnimationController, newPart: Groovy.NewPart): void => {
    const { thumb, pointer, touchEventIdentifier } = newPart;
    const spriteContainer = animController.container;
    const partController = new PartController(animController);
    const p = container.globalToLocal(pointer.x - thumb.x, pointer.y - thumb.y)
    partController.setPosition(p.x, p.y)
    partsOnStage[partController.id] = partController;
    partController.startDrag(pointer.x, pointer.y, touchEventIdentifier)
    // partController.startDrag(0, 0, touchEventIdentifier)
    container.addChild(spriteContainer);
    if (avatar !== null) {
      container.setChildIndex(avatar, container.numChildren - 1)
    }
  }

  const movePart = (partId: string, x: number, y: number) => {
    const part = partsOnStage[partId]
    if (part) {
      // console.log(y, threshold);
      if (y < threshold) {
        part.setPosition(x, y)
      } else {
        const part = partsOnStage[partId];
        container.removeChild(part.getContainer());
        delete partsOnStage[partId];
        store.dispatch(stopDragPart(partId));
        store.dispatch(removePartFromSong(partId));
      }
    }
  }

  const stopMovePart = (partId: string) => {
    const part = partsOnStage[partId]
    if (part) {
      part.ticks = keyEditor.xToTicks(part.x);
      part.pitch = keyEditor.yToPitch(part.y).number;
    }
  }


  /*
    container.addEventListener('mousedown', (e: Object) => {
      const event = e as Easeljs.MouseEvent;
      const te = event.nativeEvent as TouchEvent;
      const me = event.nativeEvent as MouseEvent;
      let x = 0;
      let y = 0;
      if (te.changedTouches) {
        const e = te.changedTouches[0];
        x = e.clientX;
        y = e.clientY;
      } else {
        x = me.clientX;
        y = me.clientY;
      }
      // console.log(x, y, event.nativeEvent.type);
      store.dispatch(setSongPositionFromX(x + pageOffsetX - keyeditorMarginLeft));
    })
  */

  const addAvatar = (a: Easeljs.Container) => {
    avatar = a;
    container.addChild(avatar);
  }


  return {
    resize,
    setPlayheadToX,
    setPageoffset,
    addPendingPart,
    movePart,
    stopMovePart,
    addAvatar,
    getContainer: () => container,
    getScoreWidth: () => scoreWidth,
  }
}

export default createGrid;
