export const CHANGE_AVATAR = 'CHANGE_AVATAR';
export const CHANGE_ANIMATION = 'CHANGE_ANIMATION';

export const changeAvatar = (url: string) => ({
  type: CHANGE_AVATAR,
  payload: {
    url,
  }
});

export const changeAnimation = (animation: string) => ({
  type: CHANGE_ANIMATION,
  payload: {
    animation,
  }
});

