import sequencer from 'heartbeat-sequencer';
import { Store, AnyAction } from 'redux';
import { AppStateAll } from '../../types';
import { getLoadedInstruments } from '../../util/action_utils'
import { loadInstrument } from '../../redux/actions/load_actions';


const createSong = (store: Store<AppStateAll, AnyAction>) => {
  const state = store.getState()
  const { app: { songPartsMenu: song, instruments }, menu: { instrumentMapping } } = state;
  let instrumentName = 'sinewave'; // local state !

  if (song === null) {
    throw (new Error())
  }

  const playTrack = (name: string) => {
    if (name === '') {
      song.stop();
      return;
    }
    const track = song.tracks.filter(t => t.name === name)[0];

    track.setInstrument(instrumentName);
    song.setTrackSolo(track, true);
    song.stop();
    song.play();
  }

  const load = (name: string) => {
    instrumentName = instruments[instrumentMapping[name]].srcname;
    const loadedInstruments = getLoadedInstruments();
    const loaded = loadedInstruments.findIndex((element: [number, string]) => element[1] === instrumentName);
    if (loaded === -1) {
      // const url = `https://heartbeatjs.org/groovy-instruments/mono-22k-q1/${instrumentName}.json`;
      // const url = `https://groovy3.heartbeatjs.org/assets/groovy-instruments/mono-mp3-112/${instrumentName}.mp3.112.json`;
      // const url = `/assets/groovy-instruments/mono-22k-q1/${instrumentName}.json`;
      let url = `/assets/groovy-instruments/mono-mp3-112/${instrumentName}.mp3.112.json`;
      if (sequencer.browser === 'firefox') {
        url = `https://heartbeatjs.org/groovy-instruments/mono-22k-q1/${instrumentName}.json`;
      }
      store.dispatch<any>(loadInstrument(url));
    }
  }

  return {
    playTrack,
    loadInstrument: load,
  }
}

export default createSong;