import { combineLatest, Observable } from 'rxjs';
import { distinctUntilKeyChanged, map, pluck } from 'rxjs/operators';
import { AppStateAll } from '../../types';

let selected$: Observable<string> | null = null;
let show$: Observable<boolean> | null = null;
let selectedTop$: Observable<[boolean, string]> | null = null
let selectedPart$: Observable<{ partId: string, x: number, y: number } | null> | null = null;
let selectedTrackName$: Observable<string> | null = null;
let partMenuScroll$: Observable<number> | null = null;


const createMenuObservers = (state$: Observable<AppStateAll>) => {
  selected$ = state$.pipe(
    pluck('menu'),
    distinctUntilKeyChanged('selectedTopMenuItem'),
    map(menu => menu.selectedTopMenuItem)
  );

  show$ = state$.pipe(
    pluck('menu'),
    distinctUntilKeyChanged('showPartMenu'),
    map(menu => menu.showPartMenu)

  );

  selectedTop$ = combineLatest(show$, selected$)

  selectedTrackName$ = state$.pipe(
    pluck('menu'),
    distinctUntilKeyChanged('selectedTrackName'),
    map(menu => menu.selectedTrackName)
  );

  partMenuScroll$ = state$.pipe(
    pluck('menu'),
    distinctUntilKeyChanged('partMenuScroll'),
    map(menu => menu.partMenuScroll)
  );
}

const handleSelectedTrackNameSubscription = (callback: (s: string) => void) => {
  if (selectedTrackName$ !== null) {
    selectedTrackName$.subscribe((selectedTrackName) => {
      callback(selectedTrackName);
    })
  }
}

const handleSelectedTopSubscription = (callback: (s: boolean, m: string) => void) => {
  if (selectedTop$ !== null) {
    selectedTop$.subscribe(data => callback(data[0], data[1]))
  }
}

const handleSelectedPartSubscription = (callback: (p: { partId: string, x: number, y: number } | null) => void) => {
  if (selectedPart$ !== null) {
    selectedPart$.subscribe(data => callback(data))
  }
}

const handlePartMenuScrollSubscription = (callback: (s: number) => void) => {
  if (partMenuScroll$ !== null) {
    partMenuScroll$.subscribe(s => callback(s))
  }
}

export {
  createMenuObservers,
  handleSelectedTopSubscription,
  handleSelectedPartSubscription,
  handleSelectedTrackNameSubscription,
  handlePartMenuScrollSubscription,
};

