import React, { Component, RefObject } from 'react';
import ReactTransitionGroup from 'react-addons-transition-group';

const FirstChild = (props: any) => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
}
interface Props {
  onClick: (value: string) => void
  className: string
  show: boolean
}

class Menu extends Component<Props> {
  private refMenu: RefObject<HTMLDivElement>
  private animDuration = 300
  constructor(props: Props) {
    super(props);
    this.refMenu = React.createRef();
  }

  animIn(callback: Function) {
    if (this.refMenu.current !== null) {
      // TweenMax.fromTo(this.refMenu.current, 0.6, { opacity: 0 }, { opacity: 1, onComplete: callback });
      // this.refMenu.current.className = 'slide-down';
      this.refMenu.current.style.animation = `slide-down ${this.animDuration}ms ease`;
      setTimeout(() => {
        callback();
      }, this.animDuration);
    }
  }

  animOut(callback: Function) {
    if (this.refMenu.current !== null) {
      // TweenMax.fromTo(this.refMenu.current, 0.6, { opacity: 1 }, { opacity: 0, onComplete: callback });
      // this.refMenu.current.className = 'slide-up';
      this.refMenu.current.style.animation = `slide-up ${this.animDuration}ms ease`;
      setTimeout(() => {
        callback();
      }, this.animDuration);
    }
  }

  componentWillEnter(callback: Function) {
    this.animIn(callback);
  }

  componentWillLeave(callback: Function) {
    this.animOut(callback);
  }

  render() {
    // for(let i = 0; i < this.props.buttons, i++) => {
    //   return <div key={`partmenubutton-${i}`}>{c}</div>
    // })
    return <div ref={this.refMenu} id="pagenavigationmenu">
      <div className="nav_left">
        <div onClick={() => { this.props.onClick('prev_page') }} className={`${this.props.className} prev_page`}></div>
        <div onClick={() => { this.props.onClick('prev_bar') }} className={`${this.props.className} prev_bar`}></div>
      </div>
      <div className="nav_right">
        <div onClick={() => { this.props.onClick('next_bar') }} className={`${this.props.className} next_bar`}></div>
        <div onClick={() => { this.props.onClick('next_page') }} className={`${this.props.className} next_page`}></div>
      </div>
    </div>
  }
}

const PageNavigationMenu = (props: Props) => (
  < ReactTransitionGroup component={FirstChild} >
    {props.show && <Menu {...props}></Menu>}
  </ReactTransitionGroup >);

export default PageNavigationMenu;

