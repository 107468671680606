import React, { Component, RefObject } from 'react';
import ReactTransitionGroup from 'react-addons-transition-group';
import classNames from 'classnames';

const FirstChild = (props: any) => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
}
interface Props {
  onClick: (value: string) => void
  className: string
  show: boolean
  maxScroll: boolean
  currentScroll: number
}

class Menu extends Component<Props> {
  private refMenu: RefObject<HTMLDivElement>
  private animDurationIn = 300
  private animDurationOut = 100
  private animEase = 'ease-out'
  constructor(props: Props) {
    super(props);
    this.refMenu = React.createRef();
  }

  animIn(callback: Function) {
    if (this.refMenu.current !== null) {
      // TweenMax.fromTo(this.refMenu.current, 0.6, { opacity: 0 }, { opacity: 1, onComplete: callback });
      // this.refMenu.current.className = 'slide-down';
      this.refMenu.current.style.animation = `slide-down ${this.animDurationIn}ms ${this.animEase}`;
      setTimeout(() => {
        callback();
      }, this.animDurationIn);
    }
  }

  animOut(callback: Function) {
    if (this.refMenu.current !== null) {
      // TweenMax.fromTo(this.refMenu.current, 0.6, { opacity: 1 }, { opacity: 0, onComplete: callback });
      // this.refMenu.current.className = 'slide-up';
      this.refMenu.current.style.animation = `slide-up ${this.animDurationOut}ms ${this.animEase}`;
      setTimeout(() => {
        callback();
      }, this.animDurationOut);
    }
  }

  componentWillEnter(callback: Function) {
    this.animIn(callback);
  }

  componentWillLeave(callback: Function) {
    this.animOut(callback);
  }

  render() {
    const classLeft = classNames(this.props.className, 'slide-left', {
      hidden: this.props.currentScroll === 0
    });
    const classRight = classNames(this.props.className, 'slide-right', {
      hidden: this.props.maxScroll === true
    });
    return <div ref={this.refMenu} id="partmenu-sliderbuttons">
      <div className="nav-left">
        <div onClick={() => { this.props.onClick('left') }} className={classLeft}></div>
      </div>
      <div className="nav-right">
        <div onClick={() => { this.props.onClick('right') }} className={classRight}></div>
      </div>
    </div>
  }
}

const PartMenuSliderScrollButtons = (props: Props) => (
  < ReactTransitionGroup component={FirstChild} >
    {props.show && <Menu {...props}></Menu>}
  </ReactTransitionGroup >);

export default PartMenuSliderScrollButtons;

