import { Easeljs } from '../../easeljs';
import { loadAvatar } from './avatar_load';
import { createjs } from '../app';
import { setSongPositionFromX, songTransport } from '../../redux/actions/song_actions';
import getStore from '../../redux/store';
import { avatarLoaded } from '../../redux/actions/load_actions';

export type AvatarUI = {
  load: (url: string) => Promise<void>,
  setAnimation: (animation: string) => void,
  handleResize: (y: number, x: number) => void,
  setPositionX: (x: number) => void,
  setPositionY: (y: number) => void,
  getContainer: () => Easeljs.Container,
  isDragging: () => boolean
}

export const createAvatarUI = (): AvatarUI => {
  const store = getStore();
  const container: Easeljs.Container = new createjs.Container();
  const offsetX: number = -195;
  const offsetY: number = -222;
  let avatar: null | Easeljs.Container = null;
  let thumb: Easeljs.Point | null = null;

  // const s: Easeljs.Shape = new createjs.Shape();
  // s.graphics.beginFill('#ff0000');
  // s.graphics.rect(100, 100, 50, 50);
  // container.addChild(s);

  const setAnimation = (animation: string): void => {
    if (avatar === null) {
      return
    }
    avatar.children.forEach((s: Easeljs.DisplayObject) => {
      const sprite = s as Easeljs.Sprite;
      sprite.framerate = 2;
      sprite.gotoAndPlay(animation);
    })
  }

  const load = async (url: string): Promise<void> => {
    avatar = await loadAvatar(url);
    container.removeAllChildren();
    avatar.x = offsetX;
    avatar.y = offsetY;
    container.addChild(avatar);
    store.dispatch(avatarLoaded());
  }

  const handleResize = (height: number, x: number): void => {
    container.y = height;
    container.x = x;
  }

  const setPositionX = (x: number): void => {
    container.x = x;
  }

  const setPositionY = (y: number): void => {
    container.y = y;
  }

  container.addEventListener('mousedown', () => {
    store.dispatch(songTransport('stop'));
  });

  container.on('pressmove', (o: Object) => {
    const e = o as Easeljs.MouseEvent
    if (thumb === null) {
      const p = container.parent.localToGlobal(e.rawX, e.rawY)
      thumb = container.globalToLocal(p.x, p.y);
      // console.log(thumb);
    }
    if (thumb !== null) {
      const newX = Math.max(e.rawX - thumb.x, 0)
      container.x = newX;
    }
  });

  container.on('pressup', () => {
    thumb = null;
    store.dispatch(setSongPositionFromX(container.x));
  })

  return {
    load,
    setAnimation,
    handleResize,
    setPositionX,
    setPositionY,
    getContainer: () => container,
    isDragging: () => thumb !== null,
  };
}