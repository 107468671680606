import { Store } from 'redux';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map, pluck } from 'rxjs/operators';
import { AppStateAll, Groovy } from '../../types';

let transport$: Observable<string> | null = null;
let scoreNavigation$: Observable<string> | null = null;
let songPositionFromX$: Observable<number> | null = null;
let playhead$: Observable<number> | null = null;
let pageOffset$: Observable<number> | null = null;
let newPart$: Observable<Groovy.NewPart | null> | null = null;
let selectedPart$: Observable<Groovy.SelectedPart> | null = null;
let removePart$: Observable<string> | null = null;
let store: Store<AppStateAll>;

const createSongObservables = (state$: Observable<AppStateAll>, s: Store<AppStateAll>) => {
  store = s;
  transport$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('transport'),
    map(song => song.transport)
  );

  scoreNavigation$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('scoreNavigation'),
    map(song => song.scoreNavigation)
  );

  songPositionFromX$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('songPositionFromX'),
    map(song => song.songPositionFromX)
  );

  playhead$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('playheadPositionX'),
    map(song => song.playheadPositionX)
  );

  newPart$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('newPart'),
    map(song => song.newPart)
  );

  pageOffset$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('pageOffsetX'),
    map(song => song.pageOffsetX)
  );

  selectedPart$ = state$.pipe(
    pluck('song'),
    // pluck('selectedPart'),
    distinctUntilKeyChanged('selectedPart'),
    map(song => song.selectedPart)
  );

  removePart$ = state$.pipe(
    pluck('song'),
    distinctUntilKeyChanged('removePart'),
    map(song => song.removePart)
  );
}

const handleSongControlSubscription = (callback: (transport: string) => void) => {
  if (transport$ !== null) {
    transport$.subscribe(transport => callback(transport));
  }
}

const handleScoreNavigationSubscription = (callback: (command: string) => void) => {
  if (scoreNavigation$ !== null) {
    scoreNavigation$.subscribe(command => callback(command));
  }
}


const handleSongPositionFromXSubscription = (callback: (x: number) => void) => {
  if (songPositionFromX$ !== null) {
    songPositionFromX$.subscribe(x => callback(x));
  }
}

const handlePlayheadPositionXSubscription = (callback: (x: number) => void) => {
  if (playhead$ !== null) {
    playhead$.subscribe((playheadPosition) => {
      callback(playheadPosition);
    })
  }
}

const handleNewPartSubscription = (callback: (part: Groovy.NewPart, spriteAnimation: Groovy.SpriteAnimation) => void) => {
  if (newPart$ !== null) {
    newPart$.subscribe((data) => {
      if (data !== null) {
        const { spriteAnimations } = store.getState().app;
        const spriteAnimation: Groovy.SpriteAnimation | null = spriteAnimations[data.trackName.substring(0, 1)];
        if (spriteAnimation !== null) {
          callback(data, spriteAnimation);
        }
      }
    })
  }
}

const handlePageoffsetSubscription = (callback: (x: number, startBar: number) => void) => {
  if (pageOffset$ !== null) {
    pageOffset$.subscribe((pageOffset) => {
      if (store !== null) {
        const {
          startBar,
        } = store.getState().song
        callback(pageOffset, startBar);
      }
    })
  }
}

const handleSelectedPartSubscription = (callback: (p: Groovy.SelectedPart) => void) => {
  if (selectedPart$ !== null) {
    selectedPart$.subscribe((selectedPart) => {
      callback(selectedPart);
    })
  }

}

const songRemovePart = (callback: (partId: string) => void) => {
  if (removePart$ !== null) {
    removePart$.subscribe((removePart) => {
      callback(removePart);
    })
  }

}

export {
  createSongObservables,
  handleSongControlSubscription,
  handleSongPositionFromXSubscription,
  handlePlayheadPositionXSubscription,
  handlePageoffsetSubscription,
  handleScoreNavigationSubscription,
  handleNewPartSubscription,
  handleSelectedPartSubscription,
  songRemovePart,
};
